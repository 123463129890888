$(document).on('turbolinks:load', () => {
  const unfoldCachedTree = entry => {
    if (!entry.classList.contains('cached')) { return false; }

    entry.classList.remove('cached');
    entry.classList.add('unfolded');
    if (entry.nextElementSibling) {
      entry.nextElementSibling.classList.remove('hide');
    }
    return true;
  }

  const foldTree = entry => {
    if (!entry.classList.contains('unfolded')) { return false; }

    entry.classList.remove('unfolded');
    entry.classList.add('cached');
    if (entry.nextElementSibling) {
      entry.nextElementSibling.classList.add('hide');
    }
    return true;
  }

  const treeFoldingHandler = event => {
    if (event.offsetX >= 0) { return; }

    event.preventDefault();

    if (unfoldCachedTree(event.target)) { return; }
    if (foldTree(event.target)) { return; }

    $.ajax({
      url: '/desktop_sub_navigations' + event.target.pathname,
      type: 'GET',
      data: { origin: window.location.pathname.substring(1) },
      success: data => {
        $(event.target.parentElement).html($(data).find(`[href="${event.target.pathname}"]`).siblings().andSelf());
      },
    });
  };

  $('nav.side-navigation').on('click', '.side-navigation--arrow', treeFoldingHandler);
});
