$(() => {
  $('[data-tooltip]').on('click', (event) => {
    var tooltip = $(event.target).children('.tooltip-content').first();

    if (event.target.dataset.state !== 'open') {
      tooltip.css('display', 'block');
      event.target.dataset.state = 'open';
    } else {
      tooltip.css('display', 'none');
      event.target.dataset.state = 'closed';
    }
  });
});
