$(document).on('turbolinks:load', () => {
  const text = $('#footer_seo_text');
  const button = $('#footer_show_more');
  if (text.height() <= 114) { return; }

  text.addClass('footer__seo-text--show-less');
  button.removeClass('hide');
  button.on('click', (event) => {
    const text = $('#footer_seo_text');

    if (text.hasClass('footer__seo-text--show-less')) {
      $(event.target).text(I18n.t('users.show.less_text'));
      text.removeClass('footer__seo-text--show-less');
    }
    else {
      $(event.target).text(I18n.t('users.show.more_text'));
      text.addClass('footer__seo-text--show-less');
    }
  });
});
