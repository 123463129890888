$(document).on('turbolinks:load', () => {
  $('[data-behavior~=product_block]').each(function(){
    let block = $(this);
    getPage(block.data('path'), block.data('block_id'), block);
  });
});

function getPage(path, blockId, block) {
  $.get(path, (data) => {
    block.find('.block-product-list').replaceWith(data);
    setNavigationAction(block);
  }).done(function(){
    updateUrl(block, blockId);
  });
}

function updateUrl(block, blockId) {
  let key = 'pages_'+blockId
  let url = new URL(window.document.URL);
  let page = block.find(".block-product-list").data('page')

  url.searchParams.set(key, page);
  if (page === 1) {
    url.searchParams.delete(key)
  } else  {
    url.searchParams.set(key, page);
  }
  history.replaceState(null, "", url.toString());
}

function setNavigationAction(block) {
  block.find('nav').find('a').each(function(){
    $(this).on('click', function(){
      getPage($(this).attr('href'), block.data('block_id'), block);
      let target_block = block
      // breakpoint('m')
      if (window.matchMedia('(min-width: 640px)').matches) {
        target_block = block.closest('.topic__block');
      }
      scroll_to_block_start(target_block);
      return false;
    });
  });
}

function scroll_to_block_start(block) {
  block[0].scrollIntoView({ behavior: "smooth" });
}
