$(document).on('turbolinks:load', () => {
  let inputField = $('[data-behavior=clear-input] input')
  let clearIcon = $('[data-behavior=clear-input] .aa-ClearIcon')

  if (!inputField[0]) return

  setClearIcon(inputField[0])

  inputField.on('input', function() {
    setClearIcon(this)
  })

  clearIcon.on('click', function() {
    inputField.val('')
    clearIcon.css('display', 'none')
  })

  function setClearIcon(input) {
    if (input.value.length == 0) {
      clearIcon.css('display', 'none')
    } else {
      clearIcon.css('display', 'block')
    }
  }
})
