$(document).on('turbolinks:load', () => {

  // Fallback for development
  const copyToClipboard = (text) => {
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val(text).trigger('select');
    document.execCommand("copy");
    $temp.remove();
  }

  const callback = function (flash) {
    flash.addClass('show');
    setTimeout(function () { flash.removeClass('show'); }, 800);
  };

  $('body').on('click', '[data-behavior~="copy-to-clipboard"]', (event) => {
    event.preventDefault();
    const flash = $('[data-behavior="show-flash"]');
    const url = document.querySelector("[data-copy]").dataset.copy;

    if (!window.isSecureContext || navigator.clipboard === undefined) {
      copyToClipboard(url);
      if (flash) callback(flash);
      return;
    }

    navigator.clipboard.writeText(url);
    if (flash) callback(flash);
  });

});
