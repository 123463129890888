import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

document.addEventListener('turbolinks:load', () => {
  new Swiper('#product_page_variations_swiper', {
    modules: [Navigation],
    spaceBetween: 16,
    breakpoints: {
      1: {
        slidesPerView: 3
      },
      420: {
        slidesPerView: 4
      },
      640: {
        slidesPerView: 3
      },
      800: {
        slidesPerView: 4
      },
      1200: {
        slidesPerView: 5
      }
    },
    navigation: {
      nextEl: '#product_page_variations_swiper_next',
      prevEl: '#product_page_variations_swiper_prev',
    },
  })
});
