import Swiper from 'swiper';
import { Autoplay, Pagination } from 'swiper/modules';

document.addEventListener('turbolinks:load', () => {
  $('[data-behavior~=banners-swiper]').each((index, el) => {
    const $this = $(el);
    $this.find('.swiper').addBack('.swiper').addClass(`banners-swiper-${index}`);
    $this.find('.swiper-pagination').addClass(`banners-swiper-pagination-${index}`);

    new Swiper(`.banners-swiper-${index}`, {
      modules: [Autoplay, Pagination],
      lazy: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: true,
      },
      pagination: {
        el: `.banners-swiper-pagination-${index}`,
        clickable: true,
      },
    });
  });
});
