document.addEventListener('turbolinks:load', () =>
  $('.footer__accordion-headline').on('click', (event) => {
    event.preventDefault();
    $(event.target).parent().toggleClass('footer__accordion--active');
  })
);

$(() => {
  const tld = window.location.hostname.substring(window.location.hostname.lastIndexOf('.') + 1);

  switch(tld) {
    case 'de':
      var tsId = 'XCFECB6C19369C9CD1A57F6BDA9F4053D';
      break;
    case 'fr':
      var tsId = 'X3D1026A22E745848D2C408A8353574B0';
      break;
    case 'com':
      var tsId = 'XCD96316085B2A95F87EAADC09ADC64A9';
      break;
  };

  if (document.getElementById('trusted-shop-info') !== null) {
    _tsConfig = {
      'yOffset': '0', /* offset from page bottom */
      'variant': 'reviews', /* default, reviews, custom, custom_reviews */
      'customElementId': '', /* required for variants custom and custom_reviews */
      'trustcardDirection': '', /* for custom variants: topRight, topLeft, bottomRight, bottomLeft */
      'customBadgeWidth': '', /* for custom variants: 40 - 90 (in pixels) */
      'customBadgeHeight': '', /* for custom variants: 40 - 90 (in pixels) */
      'disableResponsive': 'false', /* deactivate responsive behaviour */
      'disableTrustbadge': 'false', /* deactivate trustbadge */
    };
    if (document.getElementById('customCheckoutDiv') !== null) {
      _tsConfig.customCheckoutElementId = 'customCheckoutDiv'
    }
    var _ts = document.createElement('script');
    _ts.type = 'text/javascript';
    _ts.charset = 'utf-8';
    _ts.async = true;
    _ts.src = '//widgets.trustedshops.com/js/' + tsId + '.js';

    var __ts = document.getElementsByTagName('script')[0];
    __ts.parentNode.insertBefore(_ts, __ts);

    document.getElementById('hamburger-icon').addEventListener('click', () => {
      document.querySelectorAll('[id^=trustbadge-container]')[0].style.display = 'none';
    });
  }
});
