$(document).on('turbolinks:load', () => {
  $('#orders-year-selector').on('click', (event) => $(event.delegateTarget).toggleClass('open-list'));

  $('.orders__item').on('click', '.orders__products-show-more a', (event) => {
    event.preventDefault();
    const wrapper = $(event.delegateTarget).find('.orders__products-wrapper');
    const showMoreButton = $(event.target);
    const activeText = showMoreButton.text();
    const alternativeText = showMoreButton.data().alt;

    wrapper.toggleClass('show-all');
    showMoreButton.text(alternativeText);
    showMoreButton.data('alt', activeText);
  });

  $(document).on('ajax:before', '[data-behavior=toggle-details]', (event) => {
    const orderItem = $(event.target).parent();

    if (orderItem.hasClass('rendered')) {
      orderItem.toggleClass('open');
      return false;
    }

    $('body').css('cursor', 'wait');
    $(event.target).css('cursor', 'wait');
  });

  $(document).on('ajax:success', '[data-behavior=toggle-details]', (event) => {
    $('body').css('cursor', 'auto');
    $(event.target).css('cursor', 'auto');
  });
});
