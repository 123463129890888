import Swiper from 'swiper';
import { Navigation, Pagination, Thumbs } from 'swiper/modules';

import lightGallery from 'lightgallery';
import lgZoom from 'lightgallery/plugins/zoom'

document.addEventListener('turbolinks:load', () => {
  const resizeSwiper = () => {
    $('#my_page_gallery_swiper').height($('.swiper-slide').width());
  };
  let $lgSwiper = document.getElementById('lg-swipper');

  const galleryThumbs = new Swiper('#my_page_gallery_thumbs_swiper', {
    spaceBetween: 12,
    breakpoints: {
      950: {
        slidesPerView: 5
      },
      1200: {
        slidesPerView: 6
      }
    }
  });

  const gallery = new Swiper('#my_page_gallery_swiper', {
    modules: [Navigation, Pagination, Thumbs],
    lazy: true,
    navigation: {
      nextEl: '#my_page_gallery_swiper_next',
      prevEl: '#my_page_gallery_swiper_prev',
    },
    pagination: {
      el: '#my_page_gallery_swiper_pagination',
      clickable: true,
    },
    thumbs: {
      swiper: galleryThumbs
    },
    on: {
      init: function () {
        const lg = lightGallery($lgSwiper, {
          licenseKey: '33BE908B-5BD4-4B4B-BD95-D630AA373FE1',
          thumbnail: false,
          download: false,
          pager: false,
          loop: false,
          plugins: [lgZoom],
          speed: 300,
          closeOnTap: true,
          getCaptionFromTitleOrAlt: false,
          slideEndAnimation: false,
          hideControlOnEnd: true,
          mobileSettings: { showCloseIcon: true },
        });
        $lgSwiper.addEventListener('lgBeforeOpen', () => {
          document.body.classList.add('overlay__noscroll-gallery');
        });
        $lgSwiper.addEventListener('lgBeforeClose', () => {
          gallery.slideTo(lg.index, 0);
          document.body.classList.remove('overlay__noscroll-gallery');
        });
      }
    }
  });

  resizeSwiper();

  $(window).on('resize', resizeSwiper);
});
