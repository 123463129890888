module.exports = class BraintreePaypalPayment {

  constructor(paypalButtonSel, formSel) {
    this.paypalButtonSel         = paypalButtonSel;
    this.paypalButton            = $(paypalButtonSel);
    this.paymentData             = this.paypalButton.data();
    this.env                     = this.paymentData.env;
    this.locale                  = { de: 'de_DE', fr: 'fr_FR', en: 'en_US' }[this.paymentData.locale];
    this.paymentForm             = $(formSel);
    this.cancelUrl               = this.paymentData.cancelUrl;

    braintree.client.create({ authorization: this.paymentData.clientToken }, this.#braintreePaypalCheckout);
  }

  #braintreePaypalCheckout = (clientErr, clientInstance) => {
    if (clientErr) { this.#logError('Error creating client:', clientErr); return; }

    braintree.paypalCheckout.create({ client: clientInstance }, this.#createPayment);
  }

  #createPayment = (paypalCheckoutErr, paypalCheckoutInstance) => {
    if (paypalCheckoutErr) { this.#logError('Error creating PayPal Checkout:', paypalCheckoutErr); return; }

    const ppLoadOptions = {
      intent: 'capture',
      currency: this.paymentData.currency,
    };
    paypalCheckoutInstance.loadPayPalSDK(ppLoadOptions, _loadPayPalSDKErr => {
      const ppOptions = {
        flow:     'checkout',
        intent:   'capture',
        amount:   this.paymentData.amount.toString(),
        currency: this.paymentData.currency,
      };
      const buttonOptions = {
        fundingSource: paypal.FUNDING.PAYPAL,
        style: {
          size:  'responsive',
          color: 'blue',
          shape: 'rect',
          label: 'checkout',
        },
        env:         this.env,
        locale:      this.locale,
        createOrder: () => {
          this.paypalButton.addClass('clicked');
          return paypalCheckoutInstance.createPayment(ppOptions);
        },
        onApprove:   data => {
          return paypalCheckoutInstance.tokenizePayment(data).then(payload => this.#nonceReceived(payload.nonce));
        },
        onCancel:    _data => this.#cancel(),
        onError:     err => this.#logError('checkout.js error', err),
      };
      paypal.Buttons(buttonOptions).render(this.paypalButtonSel).then(() => {
        this.paypalButton.addClass('rendered');
      });
    });
  }

  #cancel = () => {
    window.location = this.cancelUrl;
  }

  #logError = (msg, error) => {
    if (!window.Rollbar) { console.error(msg, error); return; }

    Rollbar.info('Braintree Paypal payment error', { msg, error });
  }

  #nonceReceived = (nonce) => {
    this.paymentForm.find('input#payment_nonce').attr('value', nonce);
    this.paymentForm.trigger('submit');
  }

};
