module.exports = class OrderInvoiceAddress {

  constructor($el) {
    this.$wrapper  = $el;
    this.$link     = this.$wrapper.find('#order--invoice-address-trigger');
    this.$address  = this.$wrapper.find('#order--invoice-address-container');
    this.$inputs   = this.$address.find(':input');
    this.$checkbox = this.$wrapper.find('#order--invoice-address');
  }

  init() {
    if (this.$link.is(':visible')) {
      this.$inputs.attr('disabled', true);
      this.$address.addClass('disabled');
      this.$link.on('click', this.#toggle);

      if (this.$link.data('open') === true) {
        this.#show();
        this.$checkbox.prop('checked', true);
      }
    }
  }

  #toggle = () => {
    if (this.$address.hasClass('disabled') && (this.$checkbox.prop('checked') === true)) {
      this.#show();
    } else {
      this.$address.addClass('disabled');
      this.$inputs.attr('disabled', true);
      this.$address.slideUp();
    }
  }

  #show = () => {
    this.$address.removeClass('disabled');
    this.$inputs.attr('disabled', false);
    this.$address.slideDown();
  }

};
