document.addEventListener('turbolinks:load', () => {
  const passwordInput = document.getElementById('user_password');
  const passwordRepeat = document.getElementById('user_password_confirmation');
  const submitButton = document.querySelectorAll('[data-behavior~=password-validation]')[0];

  const hintBox = document.getElementById('register-hint');
  const hintLength = document.getElementById('register-length');
  const hintMatch = document.getElementById('register-match');

  if(passwordInput) {
    passwordInput.addEventListener('focus', () => {
      hintBox.classList.remove('hide');
    });

    passwordInput.addEventListener('keyup', () => {
      if (passwordInput.value.length < 8) {
        hintLength.classList.add('register__invalid');
        submitButton.setAttribute('disabled', '');
      } else if (passwordInput.value.length >= 8){
        hintLength.classList.remove('register__invalid');
        submitButton.removeAttribute('disabled', '');
      }
      if (passwordInput.value.length < 1 || passwordInput.value !== passwordRepeat.value) {
        hintMatch.classList.add('register__invalid');
        submitButton.setAttribute('disabled', '');
      } else {
        hintMatch.classList.remove('register__invalid');
        submitButton.removeAttribute('disabled', '');
      }
    });
  };

  if(passwordRepeat) {
    passwordRepeat.addEventListener('keyup', () => {
      if (passwordInput.value !== passwordRepeat.value) {
        hintMatch.classList.add('register__invalid');
        submitButton.setAttribute('disabled', '');
      } else {
        hintMatch.classList.remove('register__invalid');
        if (passwordInput.value.length > 7) {
          submitButton.removeAttribute('disabled', '');
        }
      }
    });
  };
});
