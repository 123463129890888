const scrollOffset = -380
    , $productBlock = $('#products1');

if ($('#goto_products').length && $('#goto_top').length && $productBlock.length) {
  showGotoProducts()
  window.addEventListener("scroll", function () {
    if (window.scrollY + window.innerHeight + scrollOffset > $productBlock[0].offsetTop) {
      showGotoTop()
    } else {
      showGotoProducts()
    }
  })
}

function showGotoProducts() {
  $('#goto_products').css('display', 'block')
  $('#goto_top').hide()
};

function showGotoTop() {
  $('#goto_top').css('display', 'block')
  $('#goto_products').hide()
};
