$('[data-behavior~="new-project-list"]').on('click', () => {
  $('#new-project-list-modal').removeClass('hide');
  $('body').addClass('overlay__noscroll');
});

$('[data-behavior~="add-project-list-to-cart"]').on('click', (event) => {
  $.get(`/project_lists/${event.target.dataset.id}/add_all_to_cart`, (data) => {
    $('#add_all_to_cart_placeholder').html(data);
    $('body').addClass('overlay__noscroll');
  });
});

$('[data-behavior~="project-list-settings"]').on('click', () => {
  $('#rename-project-list-modal').removeClass('hide');
  $('body').addClass('overlay__noscroll');
});

$('.product__image-container').on('click', '[data-behavior=move-item]', function (e) {
  e.preventDefault();
  const product = $(e.delegateTarget);
  if (product.length) {
    product.find('[data-move=move-item-modal]').removeClass('hide');
    $('body').addClass('overlay__noscroll');
  }
});

$('form#new_project_list').on('submit', function (e) {
  const titleInput = $(this).find('#project_list_title');
  const alert = $(this).find('.modal__notice');
  const inputText = titleInput.val().trim();
  if (!inputText.length) {
    alert.removeClass('hide');
    return false;
  } else {
    alert.addClass('hide');
    return true;
  }
});

MK.ProjectList = class ProjectList {
  constructor() {
    this.selector = '[data-behavior=projects-list]';
    this.projectListBox = new MK.ProjectListDropdown('projects-list');
    this.sortBox = new MK.ProjectListDropdown('projects-list-sort-box');
    this.listOptionSelector = '[data-behavior="projects-list-option"]';
    this.sortOptionSelector = '[data-behavior~="projects-list-sort-box-option"]';
    this.submitOptions = this.submitOptions.bind(this);
    this.selectedValues = this.selectedValues.bind(this);
    this.url = '/profile/project_list';
    this.elem = $(this.selector);

    $(this.sortOptionSelector).on('click', (event) => {
      this.submitOnClick(event, this.sortOptionSelector);
    });
    $(this.listOptionSelector).on('click', (event) => {
      this.submitOnClick(event, this.listOptionSelector);
    });
  }

  selectedValues() {
    const selectedValues = {};
    const projectOptionId = this.projectListBox.selectedValue();
    const sortOption = this.sortBox.selectedValue();

    if (!isNaN(projectOptionId) && !!projectOptionId) selectedValues['list'] = [projectOptionId]
    if (!!sortOption) selectedValues['sort_by'] = [sortOption];

    return selectedValues;
  }

  queryParams(selectedValues) {
    return (() => {
      const result = [];
      for (let filterKey in selectedValues) {
        const values = selectedValues[filterKey];
        result.push(`${filterKey}=${encodeURIComponent(values.join())}`);
      }
      return result.sort();
    })().join('&');
  }

  submitOnClick(event, optionSelector) {
    $(optionSelector).map(function () {
      $(this).removeClass('active');
    });
    $(event.target).addClass('active');
    this.submitOptions();
  }

  submitOptions() {
    window.location = this.url + '?' + this.queryParams(this.selectedValues());
  }
};

MK.toggleDropdown = function (selectorName) {
  const selector = `[data-behavior~="${selectorName}"]`;
  const toggleSelector = `[data-behavior~="${selectorName}-toggle"]`;
  const optionsSelector = `[data-behavior~="${selectorName}-options"]`;

  const selectorElem = $(selector);
  selectorElem.find(toggleSelector).on('click', (event) => {
    selectorElem.find(optionsSelector).toggleClass('show');
    event.stopPropagation();
  });

  $('html').on('click', () => {
    selectorElem.find(optionsSelector).removeClass('show');
  });
};

MK.ProjectListDropdown = class ProjectListDropdown {
  constructor(boxSelector) {
    this.elem = $(`[data-behavior~="${boxSelector}"]`);
    this.optionSelector = `[data-behavior~="${boxSelector}-option"]`;

    MK.toggleDropdown(boxSelector);
  }

  selectedValue() {
    const option = this.elem.find(`.active${this.optionSelector}`).first();
    return option.length ? $(option).data().value : null;
  }
};

$(function () {
  if ($('[data-behavior="projects-list"]').length) new MK.ProjectList();
});
