document.addEventListener('turbolinks:load', () => {
  let acc = document.querySelectorAll('[data-behavior=product-page-accordion]');

  for (let i = 0; i < acc.length; i++) {
    acc[i].addEventListener('click', function() {
      let content = this.nextElementSibling;
      if (this.classList.contains('product-page__accordion--active')) {
        content.style.maxHeight = 0;
        this.classList.toggle('product-page__accordion--active');
      } else {
        content.style.maxHeight = 'fit-content';
        this.classList.toggle('product-page__accordion--active');
      }
    });
  }

  const stars = document.getElementById('product-page-review-stars');
  const reviews = document.getElementById('product-page-reviews-wrapper');
  if (stars && reviews) {
    stars.addEventListener('click', () => {
      reviews.scrollIntoView({behavior: 'smooth'});
      reviews.click();
    });
  }

  const patternReviewsContainer = document.querySelector("[data-behavior~=pattern-load-reviews]");
  if(patternReviewsContainer) $.get(`/patterns/${patternReviewsContainer.dataset.id}/reviews`);
  const courseReviewsContainer = document.querySelector("[data-behavior~=course-load-reviews]");
  if(courseReviewsContainer) $.get(`/courses/${courseReviewsContainer.dataset.id}/reviews`);
});
