import Swiper from 'swiper';
import { Navigation, Pagination, Thumbs } from 'swiper/modules';

import lightGallery from 'lightgallery';
import lgZoom from 'lightgallery/plugins/zoom'
import lgVideo from 'lightgallery/plugins/video'

document.addEventListener('turbolinks:load', () => {
  const resizeSwiper = () => {
    $('#product_page_gallery_swiper').height($('#product_page_gallery_swiper').width());
  };
  let $lgSwiper = document.getElementById('lg-swipper');

  const galleryThumbs = new Swiper('#product_page_gallery_thumbs_swiper', {
    spaceBetween: 24,
    breakpoints: {
      640: {
        slidesPerView: 3
      },
      768: {
        slidesPerView: 4
      },
      900: {
        slidesPerView: 5
      },
      1024: {
        slidesPerView: 6
      }
    }
  });

  const gallery = new Swiper('#product_page_gallery_swiper', {
    modules: [Navigation, Pagination, Thumbs],
    lazy: true,
    navigation: {
      nextEl: '#product_page_gallery_swiper_next',
      prevEl: '#product_page_gallery_swiper_prev',
    },
    pagination: {
      el: '#product_page_gallery_swiper_pagination',
      clickable: true,
    },
    thumbs: {
      swiper: galleryThumbs
    },
    on: {
      init: function () {
        const lg = lightGallery($lgSwiper, {
          licenseKey: '33BE908B-5BD4-4B4B-BD95-D630AA373FE1',
          thumbnail: false,
          download: false,
          pager: false,
          loop: false,
          plugins: [lgZoom, lgVideo],
          speed: 300,
          closeOnTap: true,
          getCaptionFromTitleOrAlt: false,
          slideEndAnimation: false,
          hideControlOnEnd: true,
          mobileSettings: { showCloseIcon: true },
        });
        $lgSwiper.addEventListener('lgBeforeOpen', () => {
          document.body.classList.add('overlay__noscroll-gallery');
        });
        $lgSwiper.addEventListener('lgBeforeClose', () => {
          gallery.slideTo(lg.index, 0);
          document.body.classList.remove('overlay__noscroll-gallery');
        });
      }
    }
  });

  gallery.on('transitionStart', function () {
    $('#product_page_gallery_swiper .swiper-slide .swiper-slide-video').trigger('pause')
    $('#product_page_gallery_swiper .swiper-slide-active .swiper-slide-video').trigger('play')
  });

  resizeSwiper();

  $(window).on('resize', resizeSwiper);

  const videos = document.querySelectorAll('.swiper-slide-video');

  videos.forEach(function(video) {
    video.onplay = (event) => {
      event.target.style.objectFit = 'contain';
    };
    video.onpause = (event) => {
      event.target.style.objectFit = 'cover';
    };
  });
});
