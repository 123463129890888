import EmptyStarIcon from '../images/icon_star_empty.svg';
import FullStarIcon from '../images/icon_star_full.svg';

$(document).on('turbolinks:load', () => {
  $(document).on('click', '[data-behavior~=show-review-form]', function () {
    $('[data-id=review-or-support-container]').hide()
    $('[data-id="new-review-container"]').removeClass('hide')
  })

  $(document).on('click', '[data-behavior~=show-support-form]', function () {
    $('[data-id=review-or-support-container]').hide()
    $('[data-id="new-support-container"]').removeClass('hide')
  })

  $(document).on('click', '[data-behavior~=back-to-review-or-support]', function () {
    $('[data-id=review-or-support-container]').show()
    $('[data-id="new-review-container"]').addClass('hide')
    $('[data-id="new-support-container"]').addClass('hide')
  })

  if ($.fn.raty !== undefined) {
    $.fn.raty.defaults.path = '';
    $.fn.raty.defaults.starOff = EmptyStarIcon;
    $.fn.raty.defaults.starOn =  FullStarIcon;
    $.fn.raty.defaults.cancel = false;
    $.fn.raty.defaults.width = false;
  }

  $('.star-rating-container').each(function () {
    const $el = $(this);
    const $scoreTarget = $($el.data('score-target'));
    const initScore = $scoreTarget.val() || 5;
    const reviewSubmit = document.getElementById('review-submit')
    const lowReviewConfirm = document.getElementById('low-review-confirm')
    const lowReviewConfirmCheckbox = document.getElementById('low-review-confirm-checkbox')
    $scoreTarget.hide();
    if (lowReviewConfirm) {
      lowReviewConfirm.hidden = true;
    }

    $el.raty({
      score: initScore,
      click(score, _event) { // set new score to input
        if (lowReviewConfirm) {
          if (score < 4) {
            reviewSubmit.disabled = true;
            lowReviewConfirmCheckbox.checked = false
            lowReviewConfirm.hidden = false;

            lowReviewConfirmCheckbox.onclick = () => {
                if (lowReviewConfirmCheckbox.checked) {
                  reviewSubmit.disabled = false;
                } else {
                  reviewSubmit.disabled = true;
                };
              };
          } else {
            reviewSubmit.disabled = false;
            lowReviewConfirm.hidden = true;
          };
        }
        return $scoreTarget.val(score);
      }
    });
  });
});
