// Example
//    new MK.FlashMessage('hello world', 'notice').show()
// It is also possible to chain the methods
//    new MK.FlashMessage('hello world', 'error').show().addAutoHide()
module.exports = class FlashMessage {

  #$flash;

  constructor(message, message_type = 'error') {
    this.#$flash = $(
      `<div id="flash_notification" class="flash ${message_type}">
        <div class="flash__wrapper">
          ${message}
          <span class="flash__close">
            <i class="icon-cancel-circled"></i>
          </span>
        </div>
      </div>`
    );
  }

  fixedPosition = () => {
    this.#$flash.addClass('flash--fixed');
    return this;
  }

  show = () => {
    $('nav.header-nav').after(this.#$flash);
    this.#addHidingToFlash();
    return this;
  }

  // Remove the flash message after 3 seconds
  addAutoHide = () => {
    setTimeout(() => { this.#$flash.fadeOut('normal', () => this.#$flash.remove() ) }, 3000);
    return this;
  }

  #addHidingToFlash = () => {
    this.#$flash.find('.flash__close').on('click', () => this.#$flash.fadeOut('normal', () => this.#$flash.remove() ) );
  }

};
