import DownloadPdf from '../images/download_pdf.svg';

class PatternPdfDownloader {
  constructor(prepareLink, container) {
    this.pollingTimeMS = 2000;
    this.prepareLink = prepareLink;
    this.statusUrl = prepareLink.dataset.statusUrl;
    this.container = container;
    this.windowTimer = null;
    this.shopProduct = prepareLink.dataset.shopProduct;
  }

  init() {
    this.prepareLink.addEventListener('click', () => {
      this.container.innerHTML = '<div class="b-pdf-progress"><img src="/progressbar.gif"></div>';
      this.windowTimer = window.setInterval(this.checkPdfStatus.bind(this), this.pollingTimeMS);
    });
  }

  checkPdfStatus () { jQuery.get(this.statusUrl, this.verifyPdfStatusResponse.bind(this)); }

  verifyPdfStatusResponse (response) {
    if(response.status !== 'OK') { return; }

    window.clearInterval(this.windowTimer);
    if (this.shopProduct) {
      $(this.container).html(
        `<a href="${response.url}" target="_blank">
          ${I18n.t('patterns.download_links.download_pdf')} <img class="line-item__icon" src="${DownloadPdf}">
        </a>`
      );
    } else {
      $(this.container).html(
        `<a class="button button--primary button--fw button--mb-1" href="${response.url}" target="_blank">
          ${I18n.t('patterns.download_links.download_pdf')}
        </a>`
      );
    }
  }
};

document.addEventListener('turbolinks:load', () => {
  const buttons = document.querySelectorAll('[data-behavior=prepare-pattern-button]');
  if (buttons.length) {
    buttons.forEach((button) => new PatternPdfDownloader(button, button.parentNode).init());
  }
});
