$(document).on('turbolinks:load', () => {
  function getCookieValue(cookieMatcher) {
    const cookie = document.cookie.split('; ').find(row => row.match(cookieMatcher))
    if (cookie) {
      return cookie.split('=')[1]
    }
  }

  function setRecipientsProperties() {
    let recipient = {}
    let properties = {}

    const mail = $('#zl-recipient-email').data('user-email') || null
    if (mail) {
      recipient['identity'] = mail
      recipient['identity_type'] = 'email'
    }

    // Set up ga_userId property, it is the Google analytics user ID(end part of the google analytics cookie)

    const gaCookie = getCookieValue('_ga=');
    if (gaCookie) {
      let match = gaCookie.match(/GA\d\.\d\.([0-9.]+)/)
      if (match && match.length > 1) {
        properties['ga_userId'] = match[1]
      }
    }

    // Set up the spCookie property, it is the Snowplow user id(first part from the cookie)
    const spCookie = getCookieValue('_sp_id');
    if (spCookie) {
      match = spCookie.match('([^;]+);?')
      if (match && match[1]) {
        properties['sp_userId'] = match[1].split('.')[0];
      }
    }

    // Set up the shop_userId property, it is the hashed email address from dataLayer
    window.dataLayer = window.dataLayer || [];
    const userId = window.dataLayer.filter(o => o.user_email).map(o => o.user_email)
    if (userId && userId.length > 0) {
      properties['shop_userId'] = userId[0]
    }

    recipient['properties'] = properties

    const zenloop = window.Zenloop || {};
    zenloop.recipient = recipient
    window.Zenloop = zenloop
  }
  setRecipientsProperties();
});
