import OneColView from '../../images/application_2021/presentation-block.svg';
import GridView from '../../images/application_2021/presentation-grid.svg';

document.addEventListener('turbolinks:load', () => {
  const pushNewUrl = (addParam) => {
    const $filterBoxes = $('[data-behavior=filter-boxes]');
    const url = $filterBoxes.data().url;

    let params = new URLSearchParams(window.location.search);
    if (addParam) {
      params.set('one_col', true);
    } else {
      params.delete('one_col');
    }

    const stringParams = params.toString();
    const newUrl = !!stringParams ? url + '?' + stringParams : url;
    history.replaceState(null, null, newUrl);
  };

  const replacePagination = ($grid, addParam) => {
    let $links = $grid.siblings('[role="navigation"]').find('.page a');
    $links.each((_, link) => {
      let url = new URL(link.href);
      if (addParam) {
        url.searchParams.set('one_col', true);
      } else {
        url.searchParams.delete('one_col');
      }
      link.href = url.toString();
    });
  };

  $('[data-behavior="change-grid"]').on('click', function () {
    const $source = $('[data-behavior="change-grid"]');
    const $target = $('[data-behavior="change-grid-target"]');
    const $icon = $source.find('img');
    const enable_one_col = !$target.hasClass('one-col');

    $source.toggleClass('active', enable_one_col);
    $target.toggleClass('one-col', enable_one_col);
    $icon.attr('src', enable_one_col ? GridView : OneColView);

    pushNewUrl(enable_one_col);
    replacePagination($target, enable_one_col);
  });

  $('.b-mobile--menu-item[data-behavior=filter-box-option]').on('click', function (event) {
    $(this).toggleClass('active');
    return event.stopPropagation();
  });
});
