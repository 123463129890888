module.exports = class BraintreeCardPayment {

  constructor(formSel) {
    this.expiryDiv = $('#expiration-date');
    this.cvvDiv = $('#cvv');
    this.numberDiv = $('#card-number');

    this.paymentForm = $(formSel);

    this.payButton = $('#pay_button');
    this.paymentData = this.payButton.data();

    this.env = this.paymentData.env;

    this.clientEmail = $('#user-email').data().email;
    this.purchaseAmount = $('#purchase-amount').data().amount;

    this.braintreeClientInstance = null;
    this.threeDSecureClientInstance = null;

    braintree.client.create({ authorization: this.paymentData.clientToken }, this.#braintreeCardCheckout);
  }

  #billingInfo = () => {
    const billingAddress = $('#billing-address').data().address;

    return {
      givenName:         this.#removeNonAscii(billingAddress.first_name), // ASCII-printable characters required, else will throw a validation error
      surname:           this.#removeNonAscii(billingAddress.name), // ASCII-printable characters required, else will throw a validation error
      streetAddress:     billingAddress.street,
      extendedAddress:   billingAddress.house_number,
      locality:          billingAddress.city,
      postalCode:        billingAddress.zip,
      countryCodeAlpha2: billingAddress.country,
    };
  }

  #additionalInfo = () => {
    const shippingAddress = $('#shipping-address').data().address;
    if (!shippingAddress) { return ''; }

    return {
      shippingGivenName: this.#removeNonAscii(shippingAddress.first_name),
      shippingSurname:   this.#removeNonAscii(shippingAddress.name),
      shippingAddress:   {
        streetAddress:     shippingAddress.street,
        extendedAddress:   shippingAddress.house_number,
        locality:          shippingAddress.city,
        postalCode:        shippingAddress.zip,
        countryCodeAlpha2: shippingAddress.country,
      },
    };
  }

  #braintreeCardCheckout = (clientErr, clientInstance) => {
    if (clientErr) { this.#handleError(clientErr); return; }

    this.braintreeClientInstance = clientInstance;
    braintree.hostedFields.create({
      client: this.braintreeClientInstance,
      styles: {
        'input.invalid': {
          'color': 'red',
        },
        'input.valid': {
          'color': 'green',
        },
      },
      fields: {
        number: {
          selector: '#card-number',
          placeholder: this.numberDiv.attr('data-placeholder'),
        },
        cvv: {
          selector: '#cvv',
          placeholder: this.cvvDiv.attr('data-placeholder'),
        },
        expirationDate: {
          selector: '#expiration-date',
          placeholder: this.expiryDiv.attr('data-placeholder'),
        },
      }
    }, this.#processPayment);
  }

  #processPayment = (hostedFieldsErr, hostedFieldsInstance) => {
    if (hostedFieldsErr) { this.#handleError(hostedFieldsErr); return; }

    braintree.threeDSecure.create({
      version: 2, // Will use 3DS 2 whenever possible
      client:  this.braintreeClientInstance,
    }, this.#createThreeDSecure);

    $('#pay_spinner').hide();
    hostedFieldsInstance.on('validityChange', this.#checkValidityChange);

    this.payButton.on('click', (event) => {
      event.preventDefault();
      this.payButton.attr('disabled', true);
      $('#pay_spinner').show();
      hostedFieldsInstance.tokenize(this.#tokenizeFields);
    });
  }

  #createThreeDSecure = (threeDSecureErr, threeDSecureInstance) => {
    if (threeDSecureErr) { this.#handleError(threeDSecureErr); return; }

    this.threeDSecureClientInstance = threeDSecureInstance;
  }

  #checkValidityChange = (event) => {
    const field = event.fields[event.emittedBy];
    const container = $('#' + field.container.id);

    container.attr('data-valid', field.isValid);
    this.payButton.attr('disabled', !this.#allFieldsValid());
  }

  #allFieldsValid = () => {
    return (this.numberDiv.attr('data-valid') === 'true') &&
      (this.cvvDiv.attr('data-valid') === 'true') &&
      (this.expiryDiv.attr('data-valid') === 'true')
  }

  #tokenizeFields = (tokenizeErr, payload) => {
    if (tokenizeErr) { this.#handleError(tokenizeErr); return; }

    this.threeDSecureClientInstance.verifyCard({
      amount:                this.purchaseAmount,
      nonce:                 payload.nonce, // Example: hostedFieldsTokenizationPayload.nonce
      bin:                   payload.details.bin, // Example: hostedFieldsTokenizationPayload.details.bin
      email:                 this.clientEmail,
      billingAddress:        this.#billingInfo(),
      additionalInformation: this.#additionalInfo(),
      onLookupComplete:      (_data, next) => {
        // use `data` here, then call `next()`
        next();
      },
    }, this.#verifyCard);
  }

  #verifyCard = (verifyErr, response) => {
    if (verifyErr) { this.#handleError(verifyErr); return; }

    if(response) $('input#payment_nonce').attr('value', response.nonce);
    this.paymentForm.trigger('submit');
  }

  #handleError = (error) => {
    let errorCode = '';
    if (error.details && error.details.originalError)
      errorCode = '[' + error.details.originalError.code + '] ';

    const msg = errorCode + I18n.t('js.errors.payment_processing');

    this.payButton.attr('disabled', false);
    $('#pay_spinner').hide();

    new MK.FlashMessage(msg).show();

    this.#logError(error);
  }

  #logError = (error) => {
    if (!window.Rollbar) { console.error('Braintree Card payment error', error); return; }

    if (error.details && error.details.originalError) {
      const orgiError = error.details.originalError;
      error.message += ' Original Error: [' + orgiError.code + '] ' + orgiError.description;
    }
    Rollbar.info('Braintree Card payment error', error);
  }

  #removeNonAscii = (str) => {
    if ((str === null) || (str === '')) return false;

    return str.toString().replace(/[^\x20-\x7E]/g, '');
  }

};
