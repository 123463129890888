$(document).on('turbolinks:load', () => {
  const button = document.getElementById('show_all_reviews_button');
  const reviews = document.getElementById('product-reviews');
  if (!button) { return; }

  button.onclick = function() {
    document.getElementById('all_reviews').classList.remove('hide');
    button.remove();
    reviews.style.maxHeight = '100%';
  };
});
