document.addEventListener('turbolinks:load', () => {

  const productsContainer = document.querySelectorAll("[data-behavior='products-container']");
  const backToTopButton = document.querySelectorAll("[data-toggle='back-to-top-button']");
  let prevScrollPosition = 0;

  if (productsContainer) {
    document.addEventListener('scroll', toggleBackToTopButton);
  }

  function toggleBackToTopButton() {
    let newScrollPosition = window.scrollY;
    if (prevScrollPosition > newScrollPosition) {
      $(backToTopButton).fadeIn();
      $(backToTopButton).addClass('back-to-top-button--animation');
    } else if (prevScrollPosition < newScrollPosition) {
      $(backToTopButton).fadeOut();
      $(backToTopButton).removeClass('back-to-top-button--animation');
    } else if (newScrollPosition < 500) {
      $(backToTopButton).fadeOut();
      $(backToTopButton).removeClass('back-to-top-button--animation');
    }
    prevScrollPosition = newScrollPosition;
  }
});
