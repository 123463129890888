import Swiper from 'swiper';
import { Navigation, Pagination, Zoom } from 'swiper/modules';

document.addEventListener('turbolinks:load', () => {
  new Swiper('#works_page_gallery_swiper', {
    modules: [Navigation, Pagination, Zoom],
    navigation: {
      nextEl: '#works_page_gallery_swiper_next',
      prevEl: '#works_page_gallery_swiper_prev',
    },
    pagination: {
      el: '#works_page_gallery_swiper_pagination',
      clickable: true,
    },
    zoom: true,
  });
});
