import algoliasearch from 'algoliasearch/lite';
import instantsearch from 'instantsearch.js';
import { connectSearchBox } from 'instantsearch.js/es/connectors';
import {
  configure,
  refinementList,
  toggleRefinement,
  stats,
  hits,
  pagination,
  sortBy,
  panel,
} from 'instantsearch.js/es/widgets';

import MagazineIcon from '../../images/application_2021/icon-magazine.svg';
import PdfIcon from '../../images/application_2021/icon-pdf.svg';
import SuppliesIcon from '../../images/application_2021/icon-supplies.svg';
import VideoIcon from '../../images/application_2021/icon-video.svg';

const algoliaInit = document.getElementById('algolia-search-init');
const searchIndexName = algoliaInit.dataset.indexName;
const appKey = algoliaInit.dataset.appKey;
const searchClient = algoliasearch('CWUT1DIRGC', appKey);
const body = document.body;
const headline = document.getElementById('page-search-query');
const pageFilterTrigger = document.getElementById('page-search-filter-button');
const pageFilter = document.getElementById('page-search-filter');
const pageCloseButton = document.getElementById(
  'page-search-filter-close-button'
);

// define search components
const pageSearch = instantsearch({
  searchClient,
  indexName: searchIndexName,
  routing: true,
});

pageSearch.on('render', () => {
  headline.innerHTML = pageSearch._initialUiState[searchIndexName].query;
});

const saleToggleRefinement = panel({
  hidden(options) {
    let hidden = true;
    options.results.disjunctiveFacets.find((element) => {
      if (element.name == 'sale_mode' && element.data.hasOwnProperty('true')) {
        hidden = false;
      }
    });
    return hidden;
  },
  cssClasses: {
    root: ['page-search__filter-panel', 'page-search__filter-panel--toggle'],
    header: 'page-search__panel-toggle-header',
  },
  templates: {
    header: () =>
      I18n.t('search_product.sale', { locale: MkBrowserLocale.get() }),
  },
})(toggleRefinement);

const arToggleRefinement = panel({
  hidden(options) {
    let hidden = true;
    options.results.disjunctiveFacets.find((element) => {
      if (element.name == 'has_ar' && element.data.hasOwnProperty('true')) {
        hidden = false;
      }
    });
    return hidden;
  },
  cssClasses: {
    root: ['page-search__filter-panel', 'page-search__filter-panel--toggle'],
    header: 'page-search__panel-toggle-header',
  },
  templates: {
    header: () =>
      I18n.t('search_product.has_ar', { locale: MkBrowserLocale.get() }),
  },
})(toggleRefinement);

const metaCatRefinementList = panel({
  hidden(options) {
    let hidden = true;
    options.results.disjunctiveFacets.find((element) => {
      if (element.name == 'meta_categories') {
        hidden = false;
      }
    });
    return hidden;
  },
  cssClasses: {
    root: 'page-search__filter-panel',
    header: 'page-search__panel-header',
  },
  templates: {
    header: () =>
      I18n.t('search_product.hobbies', { locale: MkBrowserLocale.get() }),
  },
})(refinementList);

const typeRefinementList = panel({
  hidden(options) {
    let hidden = true;
    options.results.disjunctiveFacets.find((element) => {
      if (element.name == 'searchable_type') {
        hidden = false;
      }
    });
    return hidden;
  },
  cssClasses: {
    root: 'page-search__filter-panel',
    header: 'page-search__panel-header',
  },
  templates: {
    header: () =>
      I18n.t('search_product.type', { locale: MkBrowserLocale.get() }),
  },
})(refinementList);

const designerRefinementList = panel({
  hidden(options) {
    let hidden = true;
    options.results.disjunctiveFacets.find((element) => {
      if (element.name == 'creator_name') {
        hidden = false;
      }
    });
    return hidden;
  },
  cssClasses: {
    root: 'page-search__filter-panel',
    header: 'page-search__panel-header',
    body: 'page-search__filter-scroll',
  },
  templates: {
    header: () =>
      I18n.t('search_product.designer', { locale: MkBrowserLocale.get() }),
  },
})(refinementList);

const audienceRefinementList = panel({
  hidden(options) {
    let hidden = true;
    options.results.disjunctiveFacets.find((element) => {
      if (element.name == 'audience') {
        hidden = false;
      }
    });
    return hidden;
  },
  cssClasses: {
    root: 'page-search__filter-panel',
    header: 'page-search__panel-header',
  },
  templates: {
    header: () =>
      I18n.t('search_product.audience', { locale: MkBrowserLocale.get() }),
  },
})(refinementList);

const fabricTypeRefinementList = panel({
  hidden(options) {
    let hidden = true;
    options.results.disjunctiveFacets.find((element) => {
      if (element.name == 'fabric_type') {
        hidden = false;
      }
    });
    return hidden;
  },
  cssClasses: {
    root: 'page-search__filter-panel',
    header: 'page-search__panel-header',
    body: 'page-search__filter-scroll',
  },
  templates: {
    header: () =>
      I18n.t('search_product.fabric_type', { locale: MkBrowserLocale.get() }),
  },
})(refinementList);

const colorRefinementList = panel({
  hidden(options) {
    let hidden = true;
    options.results.disjunctiveFacets.find((element) => {
      if (element.name == 'color') {
        hidden = false;
      }
    });
    return hidden;
  },
  cssClasses: {
    root: 'page-search__filter-panel',
    header: 'page-search__panel-header',
    body: 'page-search__filter-scroll',
  },
  templates: {
    header: () =>
      I18n.t('search_product.color', { locale: MkBrowserLocale.get() }),
  },
})(refinementList);

const motivRefinementList = panel({
  hidden(options) {
    let hidden = true;
    options.results.disjunctiveFacets.find((element) => {
      if (element.name == 'motiv') {
        hidden = false;
      }
    });
    return hidden;
  },
  cssClasses: {
    root: 'page-search__filter-panel',
    header: 'page-search__panel-header',
    body: 'page-search__filter-scroll',
  },
  templates: {
    header: () =>
      I18n.t('search_product.motiv', { locale: MkBrowserLocale.get() }),
  },
})(refinementList);

const virtualSearchBox = connectSearchBox(() => {});

// add widgets and predefined search components to search
pageSearch.addWidgets([
  virtualSearchBox({}),
  configure({
    hitsPerPage: 24,
    typoTolerance: true,
    facetsRefinements: {
      locale: [MkBrowserLocale.get()],
    },
    facets: ['locale'],
  }),
  hits({
    container: '#page-search-hits',
    cssClasses: {
      root: 'page-search__hits',
    },
    transformItems(items) {
      return items.map((item) => ({
        ...item,
        productPrice: productPrice(item),
        productTypeImage: productTypeImage(item.searchable_type),
        productBadge: productBadges(item),
        productBadgeClass: productBadgeClass(item),
      }));
    },
    templates: {
      empty: (data) =>
        `<p>${I18n.t('search_product.no_product_results', {
          locale: MkBrowserLocale.get(),
          query: data.query,
        })}</p>
        <div id="dynamic_yield_no_page_search_results"></div>`,
      item: (items) =>
        `<a class="product" href="${items.object_url}">
          <div class="${items.productBadgeClass}">
            <div class="product__image-container">
              <div class="product__image">
                <img alt="${items.title}" src="${items.catalog_image}" loading="lazy" onerror="this.style.display='none'"/>
              </div>
            </div>
            <div class="product__description">
              <div class="product__title">${items.title}</div>
              <div class="product__creator">
                <span>${items.creator_name}</span>
              </div>
            </div>
            ${items.productBadge}
          </div>

          <div class="product__footer">
            <div class="product__type">
              <img src="${items.productTypeImage}"/>
            </div>
            <div class="product__price-container">
            ${items.productPrice}
            </div>
          </div>
          </a>
          `,
    },
  }),
  pagination({
    container: '#page-search-pagination',
    padding: 1,
  }),
  stats({
    container: '#page-search-stats',
    templates: {
      text: (data) =>
        `${data.nbHits} ${I18n.t('search_product.number_of_results')}`,
    },
  }),
  sortBy({
    container: '#page-search-sort-by',
    cssClasses: {
      root: 'MyCustomSortBy',
      select: ['page-search__select', 'MyCustomSortBySelect--subclass'],
    },
    items: [
      {
        label: I18n.t('search_product.sort_relevance', {
          locale: MkBrowserLocale.get(),
        }),
        value: searchIndexName,
      },
      {
        label: I18n.t('search_product.sort_price_asc', {
          locale: MkBrowserLocale.get(),
        }),
        value: `${searchIndexName}_price_asc`,
      },
      {
        label: I18n.t('search_product.sort_price_desc', {
          locale: MkBrowserLocale.get(),
        }),
        value: `${searchIndexName}_price_desc`,
      },
    ],
  }),
  saleToggleRefinement({
    container: '#page-search-sale-container',
    attribute: 'sale_mode',
    on: true,
    off: '',
    cssClasses: {
      root: 'page-search__switch',
      labelText: 'page-search__slider',
    },
    templates: {
      labelText: () => '',
    },
  }),
  arToggleRefinement({
    container: '#page-search-ar-container',
    attribute: 'has_ar',
    on: true,
    off: '',
    cssClasses: {
      root: 'page-search__switch',
      labelText: 'page-search__slider',
    },
    templates: {
      labelText: () => '',
    },
  }),
  metaCatRefinementList({
    container: '#page-search-meta-cat-container',
    attribute: 'meta_categories',
    cssClasses: {
      item: 'page-search__item',
    },
    operator: 'or',
    limit: 1000,
    sortBy: ['count:desc'],
    transformItems(items) {
      return items.map((item) => ({
        ...item,
        categoryType: I18n.t(`meta_categories.${item.value.toLowerCase()}`),
      }));
    },
    templates: {
      item: (item) =>
        `<label class="ais-refinement-list--label">
          <input type="checkbox" class="ais-RefinementList--checkbox" ${
            item.isRefined ? 'checked' : ''
          } value="${item.value}">
          <span class="checkmark"></span>
          <span class="ais-RefinementList-labelText">${item.categoryType}</span>
          <span class="ais-RefinementList-count">${item.count}</span>
        </label>`,
    },
  }),
  typeRefinementList({
    container: '#page-search-types-container',
    attribute: 'searchable_type',
    cssClasses: {
      item: 'page-search__item',
    },
    operator: 'or',
    limit: 1000,
    sortBy: ['count:desc'],
    transformItems(items) {
      return items.map((item) => ({
        ...item,
        searchableType: I18n.t(
          `search_product.searchable_type.${item.value
            .toLowerCase()
            .replace('::', '_')}`
        ),
        refinement_image: productTypeImage(item.value),
        isRefined: item.isRefined,
      }));
    },
    templates: {
      item: (item) =>
        `<label class="ais-refinement-list--label">
          <input type="checkbox" class="ais-RefinementList--checkbox" ${
            item.isRefined ? 'checked' : ''
          } value="${item.value}">
          <span class="checkmark"></span>
          <img src="${item.refinement_image}" />
          <span class="ais-RefinementList-labelText">${
            item.searchableType
          }</span>
          <span class="ais-RefinementList-count">${item.count}</span>
        </label>`,
    },
  }),
  designerRefinementList({
    container: '#page-search-designer-container',
    attribute: 'creator_name',
    cssClasses: {
      item: 'page-search__item',
      disabledShowMore: 'page-search__disabled-show-more',
      showMore: ['button', 'button--small', 'button--refinement'],
    },
    limit: 6,
    showMore: true,
    showMoreLimit: 1000,
    operator: 'or',
    searchable: true,
    searchablePlaceholder: I18n.t('search_product.designer_search', {locale: MkBrowserLocale.get()}),
    sortBy(item1, item2) {
      const item1Refined = item1.isRefined ? 1 : 0;
      const item2Refined = item2.isRefined ? 1 : 0;
      return (
        item2Refined - item1Refined || item1.name.localeCompare(item2.name)
      );
    },
    transformItems(items) {
      return items.map((item) => ({
        ...item,
        creatorName: item.value,
        isRefined: item.isRefined,
      }));
    },
    templates: {
      showMoreText: (item) =>
        `<span>${
          item.isShowingMore
            ? I18n.t('search_product.show_less', {
                locale: MkBrowserLocale.get(),
              })
            : I18n.t('search_product.show_more', {
                locale: MkBrowserLocale.get(),
              })
        }`,
      item: (item) =>
        `<label class="ais-refinement-list--label">
          <input type="checkbox" class="ais-RefinementList--checkbox" ${
            item.isRefined ? 'checked' : ''
          } value="${item.value}">
          <span class="checkmark"></span>
          <span class="ais-RefinementList-labelText">${item.creatorName}</span>
        </label>`,
    },
  }),
  audienceRefinementList({
    container: '#page-search-audience-container',
    attribute: 'audience',
    cssClasses: {
      item: 'page-search__item',
    },
    limit: 100,
    operator: 'or',
    sortBy: ['name:asc'],
    transformItems(items) {
      return items.map((item) => ({
        ...item,
        audience: item.value[0].toUpperCase() + item.value.slice(1),
        isRefined: item.isRefined,
      }));
    },
    templates: {
      item: (item) =>
        `<label class="ais-refinement-list--label">
          <input type="checkbox" class="ais-RefinementList--checkbox" ${
            item.isRefined ? 'checked' : ''
          } value="${item.value}">
          <span class="checkmark"></span>
          <span class="ais-RefinementList-labelText">${item.audience}</span>
        </label>`,
    },
  }),
  fabricTypeRefinementList({
    container: '#page-search-fabric-type-container',
    attribute: 'fabric_type',
    cssClasses: {
      item: 'page-search__item',
      disabledShowMore: 'page-search__disabled-show-more',
      showMore: ['button', 'button--small', 'button--refinement'],
    },
    limit: 6,
    showMore: true,
    showMoreLimit: 1000,
    operator: 'or',
    sortBy: ['isRefined', 'name:asc'],
    transformItems(items) {
      return items.map((item) => ({
        ...item,
        fabricType: item.value,
        isRefined: item.isRefined,
      }));
    },
    templates: {
      showMoreText: (item) =>
        `<span>${
          item.isShowingMore
            ? I18n.t('search_product.show_less', {
                locale: MkBrowserLocale.get(),
              })
            : I18n.t('search_product.show_more', {
                locale: MkBrowserLocale.get(),
              })
        }`,
      item: (item) =>
        `<label class="ais-refinement-list--label">
          <input type="checkbox" class="ais-RefinementList--checkbox" ${
            item.isRefined ? 'checked' : ''
          } value="${item.value}">
          <span class="checkmark"></span>
          <span class="ais-RefinementList-labelText">${item.fabricType}</span>
        </label>`,
    },
  }),
  colorRefinementList({
    container: '#page-search-colors-container',
    attribute: 'color',
    cssClasses: {
      item: 'page-search__item',
      disabledShowMore: 'page-search__disabled-show-more',
      showMore: ['button', 'button--small', 'button--refinement'],
    },
    limit: 6,
    showMore: true,
    showMoreLimit: 1000,
    operator: 'or',
    sortBy: ['isRefined', 'name:asc'],
    transformItems(items) {
      return items.map((item) => ({
        ...item,
        color: item.value,
        isRefined: item.isRefined,
      }));
    },
    templates: {
      showMoreText: (item) =>
        `<span>${
          item.isShowingMore
            ? I18n.t('search_product.show_less', {
                locale: MkBrowserLocale.get(),
              })
            : I18n.t('search_product.show_more', {
                locale: MkBrowserLocale.get(),
              })
        }`,
      item: (item) =>
        `<label class="ais-refinement-list--label">
          <input type="checkbox" class="ais-RefinementList--checkbox" ${
            item.isRefined ? 'checked' : ''
          } value="${item.value}">
          <span class="checkmark"></span>
          <span class="ais-RefinementList-labelText">${item.color}</span>
        </label>`,
    },
  }),
  motivRefinementList({
    container: '#page-search-motiv-container',
    attribute: 'motiv',
    cssClasses: {
      item: 'page-search__item',
      disabledShowMore: 'page-search__disabled-show-more',
      showMore: ['button', 'button--small', 'button--refinement'],
    },
    limit: 6,
    showMore: true,
    showMoreLimit: 1000,
    operator: 'or',
    sortBy: ['isRefined', 'name:asc'],
    transformItems(items) {
      return items.map((item) => ({
        ...item,
        motiv: item.value,
        isRefined: item.isRefined,
      }));
    },
    templates: {
      showMoreText: (item) =>
        `<span>${
          item.isShowingMore
            ? I18n.t('search_product.show_less', {
                locale: MkBrowserLocale.get(),
              })
            : I18n.t('search_product.show_more', {
                locale: MkBrowserLocale.get(),
              })
        }`,
      item: (item) =>
        `<label class="ais-refinement-list--label">
          <input type="checkbox" class="ais-RefinementList--checkbox" ${
            item.isRefined ? 'checked' : ''
          } value="${item.value}">
          <span class="checkmark"></span>
          <span class="ais-RefinementList-labelText">${item.motiv}</span>
        </label>`,
    },
  }),
]);
pageSearch.start();

pageFilterTrigger.addEventListener('click', function () {
  if (!pageFilter.classList.contains('page-search__filter--active')) {
    pageFilter.classList.add('page-search__filter--active');
    body.classList.add('overlay__noscroll');
  }
});

pageCloseButton.addEventListener('click', function () {
  closeFilter();
});

function closeFilter() {
  pageFilter.classList.remove('page-search__filter--active');
  body.classList.remove('overlay__noscroll');
}

function productBadges(hit) {
  let badge_html = '';
  if (hit.badge.includes('sold_out'))
    return `<div class="product__badges">
              <div class="product__badge product__badge--black">${hit.badges[0].text_desktop}</div>
            </div>`;
  else if (hit.badges?.length > 0)
    hit.badges.forEach((badge) => {
      badge_html += `<div class="product__badge ${badge.css_class}">
                      <span class="product__badge--mobile">${badge.text_mobile}</span>
                      <span class="product__badge--desktop">${badge.text_desktop}</span>
                    </div>`;
    });
  return `<div class="product__badges">${badge_html}</div>`;
}

function productBadgeClass(hit) {
  if (hit.badge.includes('sold_out') || hit.sale_mode || hit.badges?.length > 0)
    return `product__upper-section product__upper-section--badge`;
  else return `product__upper-section`;
}

function productPrice(hit) {
  const item_price = formatPrice(hit.gross_price_cents);
  const sale_price = formatPrice(hit.sale_gross_price_cents);

  return hit.sale_mode
    ? `<div class="product__price product__price--sale">${sale_price}</div>
  <div class="product__old-price">${item_price}</div>`
    : `<div class="product__price">${item_price}</div>`;
}

function formatPrice(price) {
  if (price === 0.0) {
    return I18n.t('search_product.price.free');
  }
  price = Number(price / 100.0);
  const locale = MkBrowserLocale.get();
  const currency = locale === 'en' ? 'USD' : 'EUR';
  return price.toLocaleString(locale, { style: 'currency', currency });
}

function productTypeImage(item) {
  switch (item) {
    case 'Pattern':
      return PdfIcon;
    case 'Material':
      return SuppliesIcon;
    case 'Course':
      return VideoIcon;
    case 'Shop::Product':
      return SuppliesIcon;
    default:
      return MagazineIcon;
  }
}
