$(document).on('turbolinks:load', () => {
  const proceedToPaymentButton = document.getElementById('proceed_to_payment')
  const patternNoticeCheckbox = document.getElementById('order--pattern-notice')

  if ($('.pattern-notice-container').length > 0 && proceedToPaymentButton) {
    proceedToPaymentButton.disabled = true;
  };

  if (patternNoticeCheckbox) {
    patternNoticeCheckbox.onclick = () => {
      if (patternNoticeCheckbox.checked) {
        proceedToPaymentButton.disabled = false
      } else {
        proceedToPaymentButton.disabled = true
      };
    };
  };
});
