module.exports = class Chooser {

  constructor($container) {
    this.$container = $container;
  }

  init() {
    this.#clearSelections();
    // add listener
    this.$container.on('click', '[data-behavior=chooser--option]', this.#select);
    // visually select the initially checked element
    this.$container.find('input:checked').parent().trigger('click');
  }

  #select = (event) => {
    // visually clear previous selection
    this.#clearSelections();
    $(event.currentTarget).find('input').prop('checked', true);
    $(event.currentTarget).addClass('is-selected');
  }

  #clearSelections = () => {
    this.$container.find('.is-selected').removeClass('is-selected');
  }

};
