class MkBrowserLocale {
  static get () {
    const link = window.location.hostname.lastIndexOf('.');
    let browserLocale = window.location.hostname.substring(link + 1);

    if (browserLocale === 'com')
      browserLocale = 'en';

    return browserLocale;
  }
}

window.MkBrowserLocale = MkBrowserLocale;
