import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

$(document).on('turbolinks:load', () => {
  $('[data-behavior~=teaser-swiper]').each((index, el) => {
    const $this = $(el);
    $this.find('.swiper').addClass(`teaser-swiper-${index}`);
    $this.find('.swiper-button-prev').addClass(`teaser-prev-${index}`);
    $this.find('.swiper-button-next').addClass(`teaser-next-${index}`);

    new Swiper(`.teaser-swiper-${index}`, {
      modules: [Navigation],
      lazy: true,
      slidesPerView: 1.25,
      spaceBetween: 32,
      breakpoints: {
        768: {
          slidesPerView: 2.25
        },
        1024: {
          slidesPerView: 3
        }
      },
      navigation: {
        nextEl: `.teaser-next-${index}`,
        prevEl: `.teaser-prev-${index}`,
      }
    })
  });
});
