$(() => {
  $('[data-behavior~=chooser]').each(function () {
    new MK.Chooser($(this)).init();
  });

  if ($('#order--address-wrapper').length) {
    new MK.OrderInvoiceAddress($('#order--address-wrapper')).init();
  }

  if ($('#order--shipping-address-container').length) {
    new MK.OrderShippingAddress($('#order--shipping-address-container')).init();
  }
});

// classes bottom navigation bar
if ($('.scrollTop a').length) {
  const scrollNav = () => {
    $('.scrollTop a').on('click', (event) => {
      //Animate
      $('html, body')
        .stop()
        .animate(
          { scrollTop: $($(event.target).attr('href')).offset().top - 200 },
          400
        );
      return false;
    });
    $('.scrollTop a').scrollTop();
  };
  scrollNav();
}
