$(document).on('turbolinks:load', () => {

  $('#page-navigation a').on('click', (event) => {
    const link = $(event.target);
    const closestUl = link.closest('ul');
    const closestLi = link.closest('li');
    const parallel_active_links = closestUl.find('.active[data-behavior=expandable]');
    const link_status = closestLi.hasClass('active');
    const folding = closestLi[0].dataset.behavior == 'expandable'
      && ((event.offsetX >= event.target.offsetWidth-20) || event.target.href.endsWith('#'));

    if (folding) {
      closestUl.find('ul').slideUp(200, () => {
        parallel_active_links.removeClass('active');
      });

      if (!link_status) {
        closestLi.children('ul').slideDown(200);
        closestLi.addClass('active');
      }

      // avoid scrolling to the top when (un-)folding menu items
      event.preventDefault();
    }
  });

  $('#page-main-menu').on('click', (event) => {
    $(event.target).toggleClass('page-navigation__header--open');
    $('body').toggleClass('overlay__noscroll');
    $('#page-navigation').toggle();
  });
});
