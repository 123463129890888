$(document).on('turbolinks:load', () => {
  // Will find the data-target element anywhere on the page
  // If the click is triggered on an `a` element it will not follow the link
  $('[data-behavior~=show-modal]').off('click').on('click', (event) => {
    document.body.style.top = `-${window.scrollY}px`;
    $('[data-id=' + event.currentTarget.dataset.target + ']').removeClass('hide');
    $('body').addClass('overlay__noscroll');
    return false;
  });

  // Most of the modals are placed inside of a link. This check will prevent the page from redirecting,
  // while interacting with the modal. Input events like pressing a submit button are excluded from this prevention.
  $('[data-behavior~=close-modal-bg]').off('click').on('click', (event) => {
    let validType = ['submit', 'radio', 'checkbox']
      , validTag = ['A', 'LABEL'];

    if ((validType.includes(event.target.type) || validTag.includes(event.target.tagName)) && $(event.target).closest('[data-behavior~=close-modal-bg]').length != 0) return;

    event.preventDefault();
  });

  // Close the modal by clicking outside
  $(document).off('click', '[data-behavior~=close-modal-bg]').on('click', '[data-behavior~=close-modal-bg]', (event) => {
    if(event.currentTarget == event.target) {
      event.currentTarget.classList.add('hide');
      document.body.classList.remove('overlay__noscroll');
      window.scrollTo(0, Math.abs(parseFloat(document.body.style.top)));
      document.body.style.top = 0;
    }
    return event.stopPropagation();
  });

  // Find the closet .modal__bg upwards the dom hierarchy starting by the clicked element
  $(document).off('click', '[data-behavior~=close-modal]').on('click', '[data-behavior~=close-modal]', (event) => {
    $(event.target).closest('.modal__bg').addClass('hide');
    document.body.classList.remove('overlay__noscroll');
    window.scrollTo(0, Math.abs(parseFloat(document.body.style.top)));
    document.body.style.top = 0;
  });

  // Creates a delete confirmation button in the project list edit modal
  $(document).on('click', '[data-behavior~=delete-project-list-button]', (event) => {
    const projectListId = event.target.dataset.projectListId;
    const host = window.location.origin;
    const buttonHtml = `
    <a class="button button--red button--fwm button--right" rel="nofollow" data-method="delete"
    href="${host}/project_lists/${projectListId}">${I18n.t('js.modal.confirm_deletion')}</a>`;
    event.target.outerHTML = buttonHtml;
  });
});

$('[data-offset~=modal-offset]').on('click', () => {
  document.body.style.top = `-${window.scrollY}px`;
  $('body').addClass('overlay__noscroll');
});
