document.addEventListener('turbolinks:load', () => {
  const shareYourWork = () => {
    const shareYourWorkBtn = document.querySelector('#float-button')
    if (!shareYourWorkBtn) { return; }

    const target = document.querySelector('#works-headline');
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if(entry.isIntersecting) {
          shareYourWorkBtn.classList.remove('float-button--small');
        } else {
          shareYourWorkBtn.classList.add('float-button--small');
        }
      });
    }

    const observer = new IntersectionObserver(handleIntersection);
    observer.observe(target);
  }

  const showMoreFilter = () => {
    const container = document.querySelector('.works-top-navigation');
    const showMoreBtn = document.querySelector('[data-behavior=show-more-filter]');
    const showLessBtn = document.querySelector('[data-behavior=show-less-filter]');

    if (container && showMoreBtn && showLessBtn) {
      showMoreBtn.addEventListener('click', () => {
        container.classList.add('works-top-navigation--show-more');
      });
      showLessBtn.addEventListener('click', () => {
        container.classList.remove('works-top-navigation--show-more');
      });
    }
  }

  shareYourWork();
  showMoreFilter();
});
