MK.truncate = function(string, length) {
  if (string == null) { string = ''; }
  if (length == null) { length = 10; }
  if (string.length > length) {
    const stripLength = Math.floor((length - 3) / 2); // - 3 for '...'
    const startStrip = string.substring(0, stripLength);
    const endStrip = string.substring(string.length - stripLength, string.length);
    return startStrip + '...' + endStrip;
  } else {
    return string;
  }
};

MK.truncateFilename = function(string, length) {
  // Chrome prepends file input values with "C:\fakepath\" (even on OSX, cf. http://davidwalsh.name/fakepath)
  if (string == null) { string = ''; }
  if (length == null) { length = 10; }
  const fileComponents = string.split(/\\/);
  const filename = fileComponents[fileComponents.length-1];
  return MK.truncate(filename, length);
};
