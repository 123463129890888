import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

$(document).on('turbolinks:load', () => {
  $('[data-behavior~=product-swiper]').each((index, el) => {
    const $this = $(el);
    const slidesPerSwiper = $this.find('.swiper .swiper-slide').length;

    $this.find('.swiper').addClass(`product-swiper-${index}`);
    $this.find('.swiper-button-prev').addClass(`product-swiper-prev-${index}`);
    $this.find('.swiper-button-next').addClass(`product-swiper-next-${index}`);

    // We turn "loop" on only when the total number of slides >= slidesPerView * 2.
    new Swiper(`.product-swiper-${index}`, {
      modules: [Navigation],
      slidesPerView: 2.25,
      slidesPerGroup: 2,
      spaceBetween: 16,
      loop: slidesPerSwiper >= 4.5,
      breakpoints: {
        768: {
          slidesPerView: 3,
          slidesPerGroup: 3,
          spaceBetween: 32,
          loop: slidesPerSwiper >= 6
        },
      },
      navigation: {
        prevEl: `.product-swiper-prev-${index}`,
        nextEl: `.product-swiper-next-${index}`,
      },
    })
  });
});
