document.addEventListener('turbolinks:load', () => {
  const showHint = (itemQuantity, submit_button, msg) => {
    var $hint = $('<span class="a19__hint a19__hint--error product-page__input-error"></span>');
    $hint.text(msg);
    itemQuantity.after($hint);
    submit_button.attr('disabled', 'disabled').addClass('button--disabled');
  }

  const changePrice = (event) => {
    const product_id = event.target.dataset.id;
    const base_price = event.currentTarget.querySelector(`[data-behavior~=product-base-price-data-${product_id}]`);
    if (!base_price) return;

    const base_price_amount = base_price.dataset.amount;
    const base_price_currency = base_price.dataset.currency;
    const base_price_separator = base_price.dataset.separator;
    const available_stock = parseFloat(base_price.dataset.availability);
    const minimum_stock = parseFloat(event.target.min);

    var $submit_button = $(event.currentTarget.querySelector('input[name=commit]'));
    var $itemQuantity = $(event.target);
    const quantity = event.target.value;

    $(event.target).siblings('.a19__hint--error').remove();
    if (quantity > available_stock && $itemQuantity.hasClass('float')) {
      showHint($itemQuantity, $submit_button, I18n.t('js.errors.quantity_input_to_high', { value: available_stock.toString() }));
    }
    else if (quantity > available_stock && $itemQuantity.hasClass('integer')) {
      showHint($itemQuantity, $submit_button, I18n.t('js.errors.quantity_input_to_high_piece', { value: available_stock.toString() }));
    }
    else if (quantity < minimum_stock && $itemQuantity.hasClass('float')) {
      showHint($itemQuantity, $submit_button, I18n.t('js.errors.quantity_input_to_low'));
    }
    else if (quantity < minimum_stock && $itemQuantity.hasClass('integer')) {
      showHint($itemQuantity, $submit_button, I18n.t('js.errors.quantity_input_to_low_piece'));
    }
    else {
      $submit_button.attr('disabled', false).removeClass('button--disabled');
      let total_price = ((quantity * base_price_amount) / 100).toFixed(2);
      total_price = total_price.replace('.', base_price_separator);

      priceContainer = event.currentTarget.querySelector(`[data-behavior~=product-base-price-total-${product_id}]`)
      badge = priceContainer.querySelector('.product-page__percentage-badge')

      if (base_price_currency == '€')
        total_price = `${total_price} ${base_price_currency}`
      else
        total_price = `${base_price_currency}${total_price}`

      priceContainer.innerHTML = total_price + (badge?.outerHTML || '');
    }
  }

  $(document).on('keyup change', '[data-behavior~=product-quantity-input]', changePrice);
});
