module.exports = class OrderShippingAddress {
  constructor($el) {
    this.$wrapper  = $el;
    this.$toggle   = this.$wrapper.find('#order--toggle-shipping-address-fields');
    this.$toggleIcon = this.$toggle.find('i');
    this.$address  = this.$wrapper.find('#order--shipping-address-fields');
    this.$inputs   = this.$address.find(':input');
    this.$radio    = this.$wrapper.find('input:radio.order--shipping_address_id');
    this.$packstation = this.$wrapper.find('.packstation-number-container .accordion-navigation');
  }

  init() {
    this.$address.addClass('disabled');

    if (this.$toggle.is(':visible')) {
      this.$toggle.on('click', this.#toggle);
    }

    if (this.$radio.length > 0) {
      $('input:radio.order--shipping_address_id').on('click', this.#hide);
    }

    if (this.$packstation.length > 0) {
      this.$packstation.find('> a').on('click', (event) => {
        event.preventDefault();
        this.$packstation.find('.content').toggle();
      });
    }

    // remove shipping address from the DOM after it has been successfully deleted
    $('a[data-behavior=order--destroy_shipping_address]').on('ajax:success', (event) =>
      $(event.target).closest('.order__shipping-address-select').remove());

    this.#hide();
  }

  #toggle = () => {
    if (this.$address.hasClass('disabled')) {
      this.$address.toggleClass('hidden');
      this.#show();
    } else {
      this.#hide();
      this.$address.toggleClass('hidden');
    }
  }

  #hide = () => {
    this.$toggleIcon.toggleClass('icon-plus', true);
    this.$toggleIcon.toggleClass('icon-cancel', false);
    this.$address.addClass('disabled');
    this.$inputs.attr('disabled', true);
    this.$address.slideUp();
  }

  #show = () => {
    this.$toggleIcon.toggleClass('icon-plus', false);
    this.$toggleIcon.toggleClass('icon-cancel', true);
    this.$address.removeClass('disabled');
    this.$inputs.attr('disabled', false);
    this.$radio.each(function() { $(this).prop('checked', false); });
    this.$address.slideDown();
  }
};
