module.exports = class UploadControl {

  constructor($el) {
    this.$el = $el;
    this.$fileInput = this.$el.find('input[type=file]');
    this.$imageButton = this.$el.find('[data-behavior=upload-control--image-button]');
    this.$imageButtonLabel = this.$el.find('[data-behavior=image-upload__label]');
    this.$allUploadControls = this.$el.parents('[data-behavior=upload-controls]').find('[data-behavior=upload-control]');
  }

  init() {
    this.$imageButton.on('click', this.#handleImageButtonClick);
    this.$fileInput.on('change', this.#handleFileInputChange);
  }

  #handleImageButtonClick = (event) => {
    event.preventDefault();
    this.$fileInput.trigger('click');
  }

  #handleFileInputChange = (event) => {
    // clear label if no file selected
    if (event.target.files[0] == null) {
      this.$imageButtonLabel.addClass('hidden');
      this.$imageButtonLabel.text('');
      return;
    }

    // this.$el.find('[data-behavior=upload-control--preview]').hide();
    this.$el.addClass('image-present');
    this.$imageButtonLabel.text(MK.truncateFilename(event.target.files[0].name, 20));
    this.$imageButtonLabel.removeClass('hidden');

    // try to show the next upload control
    const $nextUploadEl = this.$allUploadControls.eq(this.$allUploadControls.index(this.$el) + 1);
    if ($nextUploadEl.length != null) {
      $nextUploadEl.show();
    }
  }

};
