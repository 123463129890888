import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

$(document).on('turbolinks:load', () => {
  $('[data-behavior~=my-teaser-swiper]').each((index, el) => {
    const $this = $(el);
    $this.find('.swiper').addBack('.swiper').addClass(`my-teaser-swiper-${index}`);
    $this.find('.swiper-button-prev').addClass(`my-teaser-prev-${index}`);
    $this.find('.swiper-button-next').addClass(`my-teaser-next-${index}`);

    new Swiper(`.my-teaser-swiper-${index}`, {
      modules: [Navigation],
      lazy: true,
      slidesPerView: 1.25,
      spaceBetween: 32,
      breakpoints: {
        768: {
          slidesPerView: 3
        },
      },
      navigation: {
        prevEl: `.my-teaser-prev-${index}`,
        nextEl: `.my-teaser-next-${index}`,
      }
    });
  });
});
