document.addEventListener('turbolinks:load', () => {
  const menu = new Mmenu('#mmenu-menu', {
    extensions: ['pagedim-black'],
    setSelected: {
      current: 'detect',
      parent: 'true',
    },
    navbar: {
      title: I18n.t('js.mmenu.title'),
    },
  });

  // The display: none; is needed to avoid cumulative layout shift on page load. It gets removed after initialisation.
  document.querySelector('#mmenu-menu').style.removeProperty('display');

  const api = menu.API;
  document.querySelector('#mmenu-close').addEventListener('click', (event) => {
    event.preventDefault();
    api.close();
  });

  if ($('.mm-listitem_selected-parent.mmenu_top_level').length > 0) {
    const current_nav_ids = $('#mmenu-menu').data('nav_ids');

    current_nav_ids.forEach((id) => {
      const target_element = $(
        `.mmenu_sub_level_parent[data-sub_nav_id=${id}]`
      );
      showSubNavMenu(target_element, true);
    });
  }

  $(document).on('click', '.mmenu_sub_level_parent', (event) => {
    const target_element = $(event.target).parent();
    showSubNavMenu(target_element, false);
  });

  function showSubNavMenu(target_element, on_page_load) {
    const sub_nav_id = target_element.data('sub_nav_id');
    if (!sub_nav_id) {
      return;
    }

    $.ajax({
      url: '/mobile_sub_navigations',
      type: 'GET',
      data: {
        sub_nav_id: sub_nav_id,
        path: $('#mmenu-menu').data('path'),
        page_load: on_page_load,
      },
      async: false,
      success: (data) => {
        const listview = document.querySelector(
          '.mmenu_sub_level_panel_' + sub_nav_id
        );
        if ($(data).length == $(listview).children().length) {
          return;
        }

        $(listview).append(data);
        api.initListview(listview);
        api.initPanel(listview.parentElement);

        if (on_page_load) {
          target_element
            .removeClass('mm-listitem_selected')
            .addClass('mm-listitem_selected-parent');
          target_element
            .closest('.mm-panel')
            .removeClass('mm-panel_opened')
            .addClass('mm-hidden');
        }
      },
      complete: () => {
        if (on_page_load)
          $('.mm-listitem_selected')
            .closest('.mm-panel')
            .removeClass('mm-hidden')
            .addClass('mm-panel_opened');
      },
    });
  }
});
