document.addEventListener('turbolinks:load', () => {
  $('[data-behavior="show-second-level-nav-preview"]').on('mouseenter', (event) => {
    // reset all preview states
    $('li.active-preview').removeClass('active-preview');
    $(".show[data-behavior='second-level-nav-preview']").removeClass('show');
    $('li.nav-toggle.active').removeClass('active').addClass('current-nav');

    // add active-preview class to highlight selected item on first nav level
    $(event.target).addClass('active-preview');

    // remove highlighting of current active second level nav
    $('.b-top-nav--second-level-nav ul li.active').removeClass('active').addClass('inactive');

    // show second-level nav preview of selected item
    const entryId = event.target.dataset.entryId;
    $(`[data-behavior='second-level-nav-preview'][data-entry-id=${entryId}]`).addClass('show');
  });

  $('[data-behavior="second-level-nav-preview"]').on('mouseleave', () => {
    $(".show[data-behavior='second-level-nav-preview']").removeClass('show');
    $('li.inactive').removeClass('inactive').addClass('active');
    $('li.nav-toggle.current-nav').removeClass('current-nav').addClass('active');
    $('li.active-preview').removeClass('active-preview');
  });
});
