const error_hint = $('[data-behavior=hide-hint-on-input]').parent().siblings('.a19__hint--error');
if (error_hint.length) {
    $('[data-behavior=hide-hint-on-input]').on('input', (event) => {
        error_hint.addClass('hide')
    });
}

const b2bCouponInput = $('[data-b2b-coupon="true"]');
if (b2bCouponInput.length) {
    const html = `
    <span class="a19__hint">${I18n.t('js.hint.b2b_coupon', {locale: MkBrowserLocale.get()})}</span>`;
    let count = 0;
    b2bCouponInput.on( "keypress", function() {
        if (count === 0) {
            b2bCouponInput.after(html);
        }
        count++;
    });
    }
