MK.Filters = class Filters {
  constructor(scope) {
    this.selectedValues = this.selectedValues.bind(this);
    this.submitFilter = this.submitFilter.bind(this);
    this.deleteFilter = this.deleteFilter.bind(this);
    this.url = this.url.bind(this);
    this.selector = '[data-behavior=filter-boxes]';
    this.filterBoxSelector = '[data-behavior=filter-box]';
    this.resetButtonSelector = '[data-behavior=filter-reset-all]';
    this.submitButtonSelector = '[data-behavior=filter-box-submit]';
    this.deleteButtonSelector = '[data-behavior=filter-box-delete]';
    this.sortOptionSelector = '[data-behavior=sort-box-option]';
    this.searchSubmitSelector = '[data-behavior=submit-search]';
    this.gridModeSelector = '[data-behavior=change-grid]';
    this.showFiltersSelector = '[data-behavior=show-all-filters]';
    this.searchBoxSelector = '#search_term';

    this.elem = $(scope, this.selector);
    this.filterBoxes = this.elem.find(this.filterBoxSelector).map(function () {
      return new MK.FilterBox(this);
    });
    this.elem.find(this.resetButtonSelector).on('click', () => {
      window.location = this.url();
    });
    this.elem.find(this.submitButtonSelector).on('click', this.submitFilter);
    this.elem.find(this.deleteButtonSelector).on('click', this.deleteFilter);
    this.elem.find(this.searchSubmitSelector).on('click', this.submitFilter);
    this.elem.find(this.searchBoxSelector).on('keyup', (event) => {
      if (event.key === 'Enter') this.submitFilter();
    });

    this.elem.find(this.sortOptionSelector).on('click', (event) => {
      this.elem.find(this.sortOptionSelector).map(function () {
        $(this).removeClass('active');
        $(this).css('cursor', 'wait');
      });
      $('body').css('cursor', 'wait');
      $(event.target).addClass('active');
      this.submitFilter();
    });
  }

  url(params) {
    var url = this.elem.data().url;
    if (params) {
      url = `${url}?${params}`
    }
    if (this.elem.data().scrollTo) {
      return `${url}#${this.elem.data().scrollTo}`
    }
    return url;
  }

  selectedValues() {
    const selectedValues = {};
    this.filterBoxes.each((_i, filterBox) => {
      const values = filterBox.selectedValues();
      if (values.length > 0) {
        selectedValues[filterBox.filterKey] = values;
      }
    });
    const sortOption = this.elem.find(`${this.sortOptionSelector}.active`);
    if (sortOption.length > 0) {
      selectedValues['sort_by'] = [$(sortOption).data().ppv];
    }
    const search_term = $(this.searchBoxSelector).val();
    if (search_term && search_term.length > 0) {
      selectedValues['search_term'] = [search_term];
    }
    const gridOption = $(`${this.gridModeSelector}.active`);
    if (gridOption.length > 0) {
      selectedValues['one_col'] = [true];
    }
    const showAllFiltersOption = $(`${this.showFiltersSelector}.open`);
    if (showAllFiltersOption.length > 0) {
      selectedValues['show_all'] = [true];
    }
    return selectedValues;
  }

  queryParams(selectedValues) {
    return (() => {
      const result = [];
      for (let filterKey in selectedValues) {
        const values = selectedValues[filterKey];
        result.push(`${filterKey}=${encodeURIComponent(values.join())}`);
      }
      return result.sort();
    })().join('&');
  }

  submitFilter() {
    window.location = this.url(this.queryParams(this.selectedValues()));
  }

  deleteFilter(event) {
    const filter = event.target.dataset['property'];
    if (filter) {
      const updatedValues = this.selectedValues();
      delete updatedValues[filter];
      window.location = this.url(this.queryParams(updatedValues));
    }
  }
};

MK.FilterBox = class FilterBox {
  constructor(elem) {
    this.elem = elem;
    this.toggleSelector = '[data-behavior=filter-box-options-toggle]';
    this.optionsSelector = '[data-behavior=filter-box-options]';
    this.optionSelector = '[data-behavior=filter-box-option]';

    this.elem = $(this.elem);
    this.filterKey = this.elem.data().property;

    this.closeWhenClickOutside = () => {
      this.elem.find(this.optionsSelector).removeClass('show');
    }

    this.elem.find(this.toggleSelector).on('click', (event) => {
      var $options = this.elem.find(this.optionsSelector);
      if ($options.hasClass('show')) {
        $('html').off('click', this.closeWhenClickOutside);
      } else {
        $('html').one('click', this.closeWhenClickOutside);
      }
      $options.toggleClass('show');
      event.stopPropagation();
    });
    this.elem.find(this.optionSelector).on('click', (event) => {
      $(event.target).toggleClass('active');
      event.stopPropagation();
    });
  }

  selectedOptions() {
    return this.elem.find(`${this.optionSelector}.active`);
  }

  selectedValues() {
    return Array.from(this.selectedOptions()).map((opt) => $(opt).data().ppv);
  }
};

$(() => {
  $('[data-behavior=filter-boxes]').each((_, el) => new MK.Filters(el));
});

$('.b-nav-filters__show-all-link').on('click', (event) => {
  event.preventDefault();
  const showMoreButton = $(event.target);
  const activeText = showMoreButton.text();
  const alternativeText = showMoreButton.data().alt;

  $('.catalog-filter-box').toggleClass('open');
  showMoreButton.text(alternativeText);
  showMoreButton.data('alt', activeText);
});
