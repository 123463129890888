module.exports = ($el) => {
  $el.on('click', 'i', (event) => {
    const $icon = $(event.target);
    const $container = $(event.delegateTarget);
    const $input = $container.find('input');

    const newType = $input.attr('type') === 'password' ? 'text' : 'password';
    $input.attr('type', newType);
    $icon.toggleClass('is-viewable');

    const tmp = $icon.attr('title');
    $icon.attr('title', $icon.data('alternative-title'));
    $icon.data('alternative-title', tmp);
  });
};
