$(document).on('turbolinks:load', () => {
  const bio = $('#user_biography');
  const button = $('#user_show_more');
  if (bio.height() <= 144) { return; }

  bio.addClass('user__biography--show-less');
  button.removeClass('hide');
  button.on('click', (event) => {
    const bio = $('#user_biography');
    if (bio.hasClass('user__biography--show-less')) {
      $(event.target).text(I18n.t('users.show.less_text'));
      bio.removeClass('user__biography--show-less');
    } else {
      $(event.target).text(I18n.t('users.show.more_text'));
      bio.addClass('user__biography--show-less');
    }
  });
});
