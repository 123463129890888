import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

document.addEventListener('turbolinks:load', () => {
  new Swiper('#course_video_thumbs_swiper', {
    modules: [Navigation],
    navigation: {
      nextEl: '#course_video_thumbs_swiper_next',
      prevEl: '#course_video_thumbs_swiper_prev',
    },
    breakpoints: {
      1: {
        slidesPerView: 2,
        spaceBetween: 16,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 16,
      },
    },
  });

  $('.faq__question').on('click', (e) => {
    e.preventDefault();
    const closestBlock = $(e.target).closest('.faq__question-block');
    closestBlock.toggleClass('faq__question-block--active');
  });

  $('.faq__more-qestions-button').on('click', (e) => {
    e.preventDefault();
    const closestBlock = $(e.target).closest('.course__faq');
    closestBlock.toggleClass('course__faq--show-additional-questions');
  });

  $('.course__video-thumb').on('click', (e) => {
    e.preventDefault();
    let clicked = $(e.target).closest('.course__video-thumb');

    $('.course__video-thumb').removeClass('swiper-slide-thumb-active');
    $('.course__faq-wrapper--active').removeClass(
      'course__faq-wrapper--active'
    );
    $('.faq__question-block--active').removeClass(
      'faq__question-block--active'
    );
    clicked.addClass('swiper-slide-thumb-active');

    $('#course-video-iframe').attr('src', clicked.attr('data-vimeo-url'));
    $('#course-video-title').text(clicked.attr('data-title'));
    $('#course-video-duration').text(clicked.attr('data-duration'));
    $(`#episode_${clicked.attr('data-episode-id')}`).addClass(
      'course__faq-wrapper--active'
    );
  });
});
